<template>
  <div>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="2" class="d-flex align-items-center">
            <label style="width: 5rem">选择年份</label>
            <v-select
                v-model="selected_year"
                :options="year_list"
                :clearable="false"
                :placeholder="$t('No filter')"
                class="w-100"
            />
          </b-col>
          <!-- <b-col cols="3">
    <p style="margin-bottom: 0.5rem">能源类型</p>
    <v-select
        disabled
        v-model="selected_energy_type"
        :options="energy_type_list"
        :clearable="false"
        :reduce="(text) => text.value"
        label="text"
        :placeholder="$t('No filter')"
        class="w-100"
    />
</b-col> -->

          <b-col cols="2" offset="8" class="text-right">
            <b-button v-b-modal.model-set-quotas variant="primary"
            >配置定额
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <!-- 年定额 -->
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-title> 年定额</b-card-title>
          <b-card-text>
            <b-row>
              <b-col cols="3">
                <b-card border-variant="warning">
                  <b-card-text
                      class="
                                            d-flex
                                            justify-content-between
                                            align-items-center
                                        "
                  >
                    <div>
                      <label>设定限额</label><br/>
                      <span
                          class="
                                                    h2
                                                    text-warning
                                                    font-digit
                                                "
                      >{{ year_quotas }}</span
                      ><small class="ml-50">{{
                        !selected_energy_type
                            ? "kWh"
                            : "T"
                      }}</small>
                    </div>
                    <b-avatar
                        variant="light-warning"
                        size="lg"
                    >
                      <feather-icon
                          icon="StarIcon"
                          size="24"
                      />
                    </b-avatar>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col cols="3">
                <b-card border-variant="primary">
                  <b-card-text
                      class="
                                            d-flex
                                            justify-content-between
                                            align-items-center
                                        "
                  >
                    <div>
                      <label>实际消耗</label><br/>
                      <span
                          :class="
                                                    'text-' +
                                                    value_compare(
                                                        year_energy,
                                                        year_quotas
                                                    )
                                                "
                          class="
                                                    h2
                                                    text-primary
                                                    font-digit
                                                "
                      >{{ year_energy }}</span
                      ><small class="ml-50">{{
                        !selected_energy_type
                            ? "kWh"
                            : "T"
                      }}</small>
                    </div>
                    <b-avatar
                        variant="light-primary"
                        size="lg"
                    >
                      <feather-icon
                          icon="ActivityIcon"
                          size="24"
                      />
                    </b-avatar>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col cols="3">
                <b-card border-variant="danger">
                  <b-card-text
                      class="
                                            d-flex
                                            justify-content-between
                                            align-items-center
                                        "
                  >
                    <div>
                      <label>已用配额</label><br/>
                      <span
                          :class="
                                                    'text-' +
                                                    value_compare(
                                                        year_energy,
                                                        year_quotas
                                                    )
                                                "
                          class="
                                                    h2
                                                    text-danger
                                                    font-digit
                                                "
                      >{{
                          (
                              (year_energy /
                                  year_quotas) *
                              100
                          ).toFixed(1)
                        }}</span
                      ><small class="ml-50">%</small>
                    </div>
                    <b-avatar
                        variant="light-danger"
                        size="lg"
                    >
                      <feather-icon
                          icon="PieChartIcon"
                          size="24"
                      />
                    </b-avatar>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col cols="3">
                <b-card border-variant="success">
                  <b-card-text
                      class="
                                            d-flex
                                            justify-content-between
                                            align-items-center
                                        "
                  >
                    <div>
                      <label>剩余配额</label><br/>
                      <span
                          :class="
                                                    'text-' +
                                                    value_compare(
                                                        year_energy,
                                                        year_quotas
                                                    )
                                                "
                          class="
                                                    h2
                                                    text-success
                                                    font-digit
                                                "
                      >{{
                          (
                              (1 -
                                  year_energy /
                                  year_quotas) *
                              100
                          ).toFixed(1)
                        }}</span
                      ><small class="ml-50">%</small>
                    </div>
                    <b-avatar
                        variant="light-success"
                        size="lg"
                    >
                      <feather-icon
                          icon="PieChartIcon"
                          size="24"
                      />
                    </b-avatar>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>

            <b-progress
                :max="year_quotas"
                :class="
                                'progress-bar-' +
                                value_compare(year_energy, year_quotas)
                            "
                height="1.5rem"
            >
              <b-progress-bar
                  :value="year_energy"
                  :label="
                                    ((year_energy / year_quotas) * 100).toFixed(
                                        2
                                    ) + '%'
                                "
                  striped
                  height="10rem"
                  animated
              ></b-progress-bar>
            </b-progress>

            <div class="d-flex justify-content-between mt-50">
                            <span
                                v-for="(item, index) in percent_number"
                                :key="index"
                            >{{ item }}%</span
                            >
            </div>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- 月定额 -->
      <b-col cols="5">
        <b-card>
          <b-card-title> 月定额</b-card-title>
          <b-card-text
              id="month_chart"
              style="height: 300px"
          ></b-card-text>
        </b-card>
      </b-col>

      <!-- 日定额 -->
      <b-col cols="7">
        <b-card>
          <b-card-title> 日定额</b-card-title>
          <b-card-text>
            <b-card-text
                id="day_chart"
                style="height: 300px"
            ></b-card-text>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
        id="model-set-quotas"
        v-model="modal_set_quotas_settings.show"
        title="配置定额"
        button-size="sm"
        ok-title="确定"
        cancel-title="取消"
        cancel-variant="outline-primary"
        centered
        size="lg"
        no-close-on-backdrop
        @ok.prevent="submit"
    >
      <b-row>
        <b-col cols="3" class="d-flex align-items-center">
          <label style="width: 5rem">选择年份</label>
          <v-select
              v-model="modal_set_quotas.year"
              :options="year_list"
              :clearable="false"
              :placeholder="$t('No filter')"
              class="w-100"
          />
        </b-col>

        <!-- <b-col cols="4" class="d-flex align-items-center">
    <label style="width: 5rem">能源类型</label>
    <v-select
        disabled
        v-model="modal_set_quotas.energy_type"
        :options="energy_type_list"
        :clearable="false"
        :reduce="(text) => text.value"
        label="text"
        :placeholder="$t('No filter')"
        class="w-100"
    />
</b-col> -->
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <div class="font-weight-bolder" style="font-size: 1.2rem">
            年定额
          </div>
          <b-row class="my-1">
            <b-col cols="8" class="d-flex align-items-center">
              <b-form-input
                  v-model="modal_set_quotas_settings.year_value"
                  number
                  :state="
                                    modal_set_quotas_settings.year_value > 0
                                "
              ></b-form-input>
              <small class="ml-50" style="width: 5rem">{{
                  energy_type_list[modal_set_quotas.energy_type]
                      .unit
                }}</small>
            </b-col>
            <b-col cols="4" class="text-right">
              <b-button @click="split_by_month" variant="primary"
              >按月均分
              </b-button>
              <b-button
                  @click="split_by_day"
                  class="ml-1"
                  variant="primary"
              >按天均分
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <div class="font-weight-bolder" style="font-size: 1.2rem">
            月定额
          </div>
          <b-row>
            <b-col
                v-for="(item, index) in month_list"
                :key="index"
                class="d-flex align-items-center mt-1"
                cols="4"
            >
                            <span class="mr-50" style="width: 10rem">
                                {{ item.text }}
                                <small class="text-secondary"
                                >({{
                                    modal_set_quotas_settings.month_days[
                                        index
                                        ]
                                  }})</small
                                >
                            </span>
              <b-form-input
                  v-model="modal_set_quotas.month_value[index]"
                  number
                  :state="modal_set_quotas.month_value[index] > 0"
              ></b-form-input>
              <small class="ml-50" style="width: 5rem">{{
                  energy_type_list[modal_set_quotas.energy_type]
                      .unit
                }}</small>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRefs, watch} from "@vue/composition-api";
import {Column, Line} from "@antv/g2plot";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import {momentJs} from "@/libs/utils/moment";
import {showToast} from "@/libs/utils/showToast";
import {getEnergyDataList, getEnergyQuotas, setEnergyQuotas,} from "@/api/jsf35";

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  setup() {
    const percent_number = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const year_list = [
      momentJs().add(1, "years").format("YYYY"),
      momentJs().format("YYYY"),
      momentJs().subtract(1, "years").format("YYYY"),
    ];
    let month_list = [
      {text: "一月", value: 1},
      {text: "二月", value: 2},
      {text: "三月", value: 3},
      {text: "四月", value: 4},
      {text: "五月", value: 5},
      {text: "六月", value: 6},
      {text: "七月", value: 7},
      {text: "八月", value: 8},
      {text: "九月", value: 9},
      {text: "十月", value: 10},
      {text: "十一月", value: 11},
      {text: "十二月", value: 12},
    ];
    const calendar_locale = {
      zh: {
        labelPrevYear: "上一年",
        labelPrevMonth: "上个月",
        labelCurrentMonth: "当前月份",
        labelNextMonth: "下个月",
        labelNextYear: "明年",
        labelToday: "今天",
        labelSelected: "选定日期",
        labelNoDateSelected: "未选择日期",
        labelCalendar: "日历",
        labelNav: "日历导航",
        labelHelp: "使用光标键浏览日期",
      },
    };
    const energy_type_list = [
      {text: "电", value: 0, unit: "kWh"},
      {text: "水", value: 1, unit: "T"},
    ];

    let month_chart = ref(null);
    let day_chart = ref(null);

    const event = reactive({
      selected_year: momentJs().format("YYYY"),
      selected_month: parseInt(momentJs().format("M")),
      selected_energy_type: 0,
      //用于展示定额数据
      year_quotas: 100,
      month_quotas: [],
      year_energy: 0,
      month_energy: [],
      month_day_avg_value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      day_quotas: {},
      day_energy: {},
      legend: [
        {
          color: "bg-success",
          text: "低于日定额",
        },
        {
          color: "bg-warning",
          text: "约等于日定额",
        },
        {
          color: "bg-danger",
          text: "高于日定额",
        },
        {
          color: "bg-primary",
          text: "当前日",
        },
      ],
      date: [
        {
          title: "低于定额日",
          num: 22,
        },
        {
          title: "约等于定额日",
          num: 5,
        },
        {
          title: "高于定额日",
          num: 3,
        },
      ],
    });

    const default_month_value = () => {
      let month_quotas = [];
      let month_energy = [];
      month_list.map((month) => {
        let data_item = {
          time: month.text,
          value: 0,
        };
        month_quotas.push({type: "定额", ...data_item});
        month_energy.push({type: "能耗", ...data_item});
      });
      event.month_quotas = month_quotas;
      event.month_energy = month_energy;
    };

    //提交定额数据
    const modal_set_quotas = ref({
      id: null,
      year: event.selected_year,
      energy_type: event.selected_energy_type,
      month_value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    });
    const modal_set_quotas_settings = ref({
      show: false,
      year_value: 0,
      month_days: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      year_days: 0,
    });

    //计算选中年份各月天数
    const count_days = (year) => {
      let year_days = 0;
      let month_days = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let days_arr = {};
      for (let i = 0; i < 12; i++) {
        const month = i + 1;
        const days = momentJs(
            year + "-" + month,
            "YYYY-M"
        ).daysInMonth();
        const month_str =
            year + "-" + (month < 10 ? "0" + month : month) + "-";
        for (let d = 1; d <= days; d++) {
          let day_str = month_str + (d < 10 ? "0" + d : d);
          days_arr[day_str] = {time: day_str, value: 0};
        }
        month_days[i] = days;
        year_days += days;
      }
      modal_set_quotas_settings.value.month_days = month_days;
      modal_set_quotas_settings.value.year_days = year_days;
      event.day_energy = days_arr;
      event.day_quotas = days_arr;
    };

    //选中能耗类型变化时，重新获取定额和能耗数据
    watch(
        () => event.selected_energy_type,
        (value) => {
          modal_set_quotas.value.energy_type = value;
          get_quotas();
          get_energy_data();
        }
    );
    //选中年份变化时，重新获取定额和能耗数据
    watch(
        () => event.selected_year,
        (value) => {
          modal_set_quotas.value.year = value;
          get_quotas();
          get_energy_data();
        }
    );

    //计算选定年份的各月天数
    watch(
        () => modal_set_quotas.value.year,
        (year) => {
          count_days(year);
          modal_set_quotas.value.month_value = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          ];
        }
    );

    //月份定额变化时，自动计算年定额
    watch(
        () => modal_set_quotas.value.month_value,
        (month_value) => {
          let data = array_sum(month_value);
          modal_set_quotas_settings.value.year_value =
              Math.round(data * 100) / 100;
        }
    );

    //按月均分
    const split_by_month = () => {
      let year_value = modal_set_quotas_settings.value.year_value;
      let month_value = [];
      const month_data = Math.round((year_value / 12) * 100) / 100;
      for (let i = 0; i < 12; i++) {
        month_value[i] = month_data;
      }
      modal_set_quotas.value.month_value = month_value;
    };
    //按天均分
    const split_by_day = () => {
      let year_value = modal_set_quotas_settings.value.year_value;
      let month_value = [];
      const day_data =
          Math.round(
              (year_value / modal_set_quotas_settings.value.year_days) *
              100
          ) / 100;
      for (let i = 0; i < 12; i++) {
        const days = modal_set_quotas_settings.value.month_days[i];
        month_value[i] = Math.round(days * day_data * 100) / 100;
      }
      modal_set_quotas.value.month_value = month_value;
    };

    const submit = () => {
      const data = JSON.parse(JSON.stringify(modal_set_quotas.value));
      data.project_id = 72;
      let check = 1;
      data.month_value.map((month_data, index) => {
        if (month_data <= 0) {
          check = 0;
        }
      });
      if (check) {
        data.month_value = data.month_value.join();
        setEnergyQuotas(data)
            .then((res) => {
              showToast(null, "成功", "能源定额设置成功");
              modal_set_quotas_settings.value.show = false;
              get_quotas(
                  modal_set_quotas.value.year,
                  modal_set_quotas.value.energy_type
              );
            })
            .catch((error) => {
              showToast(error);
            });
      } else {
        showToast(null, "提示", "定额数据异常, 各月定额数据应大于0");
      }
    };

    //获取定额数据
    const get_quotas = (year, energy_type) => {
      year = year || event.selected_year;
      energy_type = energy_type || event.selected_energy_type;
      const data = {
        project_id: 72,
        year: year,
        energy_type: energy_type,
      };
      getEnergyQuotas(data)
          .then((res) => {
            let data = res.data;
            let month_value = data.month_value.split(",");
            event.year_quotas = array_sum(month_value);
            month_value.map((value, index) => {
              const month_days =
                  modal_set_quotas_settings.value.month_days[index];
              event.month_quotas[index].value = value;
              event.month_day_avg_value[index] =
                  Math.round((value / month_days) * 100) / 100;
            });
            console.log("month_quotas", event.month_quotas);
            change_chart_data("d");
            change_chart_data("m");
            showToast(null, "成功", "获取定额数据成功");
          })
          .catch((error) => {
            event.year_quotas = 0;
            event.month_quotas.map((item, index) => {
              event.month_quotas[index].value = 0;
            });
            change_chart_data("d");
            change_chart_data("m");
            showToast(null, "错误", "查询到定额数据");
          });
    };

    //获取整年能源数据
    const get_energy_data = () => {
      const date = momentJs(event.selected_year);
      const data = {
        project_id: 72,
        time:
            "(" +
            date.startOf("year").format("YYYY-MM-DD HH:mm:ss") +
            "," +
            date.endOf("year").format("YYYY-MM-DD HH:mm:ss") +
            ")",
        energy_type: event.selected_energy_type === 0 ? "电" : "水",
        posisid: 200099
      };
      const month_arr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const days_arr = JSON.parse(JSON.stringify(event.day_energy));
      //清空能耗数据
      for (const i in days_arr) {
        days_arr[i]["value"] = 0;
      }

      //获取年能耗
      getEnergyDataList({...data, table: "y"})
          .then((res) => {
            console.log("year", res.data.list);
            let year_energy = 0;
            res.data.list.map((item) => {
              const value = item.value || 0;
              const time = item.time.split(" ")[0];
              year_energy += value;
            });
            event.year_energy = Math.round(year_energy * 100) / 100;
          })
          .catch((error) => {
            showToast(error);
          });
      getEnergyDataList({...data, table: "m"})
          .then((res) => {
            console.log("month", res.data.list);
            res.data.list.map((item) => {
              const value = item.value || 0;
              const time = item.time.split(" ")[0];
              const month_index =
                  parseInt(momentJs(time, "YYYY-MM-DD").format("M")) -
                  1;
              month_arr[month_index] =
                  (month_arr[month_index] || 0) + value;
            });
            for (const i in month_arr) {
              event.month_energy[i].value =
                  Math.round(month_arr[i] * 100) / 100;
            }
            change_chart_data("m");
          })
          .catch((error) => {
            showToast(error);
          });
      getEnergyDataList({...data, table: "d"})
          .then((res) => {
            res.data.list.map((item) => {
              const value = item.value || 0;
              const time = item.time.split(" ")[0];
              days_arr[time]["value"] =
                  days_arr[time]["value"] + value;
            });
            for (const i in days_arr) {
              days_arr[i]["value"] =
                  Math.round(days_arr[i]["value"] * 100) / 100;
            }
            event.day_energy = days_arr;
            change_chart_data("d");
          })
          .catch((error) => {
            showToast(error);
          });
    };

    //更新图表数据
    const change_chart_data = (type = "d") => {
      if (type === "d") {
        let day_energy = JSON.parse(JSON.stringify(event.day_energy));
        let day_quotas = JSON.parse(JSON.stringify(event.day_quotas));
        for (const day_str in day_energy) {
          const month_index =
              parseInt(momentJs(day_str, "YYYY-MM-DD").format("M")) -
              1;
          day_quotas[day_str] = {
            time: event.day_energy[day_str].time,
            value: event.month_day_avg_value[month_index],
            type: "定额",
          };
          day_energy[day_str].type = "能耗";
        }
        event.day_energy = day_energy;
        event.day_quotas = day_quotas;
        const day_energy_arr = Object.values(day_energy);
        const day_quotas_arr = Object.values(day_quotas);
        const day_data = [...day_energy_arr, ...day_quotas_arr];
        day_chart.value.changeData(day_data);
      } else {
        const month_data = [
          ...event.month_energy,
          ...event.month_quotas,
        ];
        month_chart.value.changeData(month_data);
      }
    };

    //数组求和
    const array_sum = (array) => {
      let sum = 0;
      array.map((data) => {
        sum += parseFloat(data) || 0;
      });
      sum = Math.round(sum * 100) / 100;
      return sum;
    };

    const value_compare = (energy_value, quotas_value) => {
      let status = "primary";
      if (energy_value / quotas_value > 1) {
        status = "danger";
      } else if (energy_value / quotas_value > 0.8) {
        status = "warning";
      }
      return status;
    };

    const init_month_chart = () => {
      const chart = new Column("month_chart", {
        data: [...event.month_energy, ...event.month_quotas],
        isGroup: true,
        xField: "time",
        yField: "value",
        seriesField: "type",
        groupField: "type",
        legend: {
          position: "bottom",
        },
      });
      chart.render();
      month_chart.value = chart;
    };

    const init_day_chart = () => {
      const chart = new Line("day_chart", {
        data: [
          ...Object.values(event.day_energy),
          ...Object.values(event.day_quotas),
        ],
        isGroup: true,
        xField: "time",
        yField: "value",
        seriesField: "type",
        groupField: "type",
        legend: {
          position: "bottom",
        },
        slider: {
          start: 0,
          end: 1,
          handlerStyle: {
            height: 18,
          },
          textStyle: {
            fill: "#eeeeee",
            fillOpacity: 1,
            shadowColor: "black",
            shadowBlur: 1,
          },
          trendCfg: {
            lineStyle: {
              fillOpacity: 1,
            },
          },
        },
      });
      chart.render();
      day_chart.value = chart;
    };

    onMounted(() => {
      init_month_chart();
      init_day_chart();
      default_month_value();
      count_days(modal_set_quotas.value.year);
      get_quotas();
      get_energy_data();
    });
    return {
      ...toRefs(event),
      percent_number,
      month_list,
      calendar_locale,
      year_list,
      modal_set_quotas,
      modal_set_quotas_settings,
      energy_type_list,
      split_by_month,
      split_by_day,
      submit,
      get_quotas,
      value_compare,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .b-calendar-header,
  .b-calendar-nav,
  .b-calendar-grid-help {
    display: none !important;
  }

  .modal-lg,
  .modal-xl {
    max-width: 1000px !important;
  }
}

.tab {
  height: 100%;

  .content {
    height: 100%;
    background: yellow;

    .ct {
      height: 10%;
      background: #00feff;
    }

    .cb {
      height: 90%;
      background: #00ff80;
    }
  }
}
</style>
